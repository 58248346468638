import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import axios from '../../utils/axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import './PhoneChange.css';
import { phoneChangeState } from '../../recoil/phoneChangeAtom';
import { formState } from '../../recoil/formAtom';
const PhoneChange = ({ open, handleClose }) => {
  const [phoneChange, setPhoneChange] = useRecoilState(phoneChangeState);
  const currentPhone = useRecoilValue(formState);
  const [error, setError] = useState('');

  const handlePhoneChange = ({ target: { value } }) => {
    setError('');
    setPhoneChange(prev => ({ ...prev, number: value }));
  };

  const handleSubmit = () => {
    setError('');
    if (!phoneChange.number) {
      setError('Please fill the number');
    } else if (phoneChange.number === currentPhone.hpNumber) {
      setError('Please enter a different number');
    } else {
      axios
        .post('profile/update/hp_number', { hp_number: `65${phoneChange.number}` })
        .then(data => {
          setPhoneChange(prev => ({ ...prev, openPhoneChangeVerify: true }));
          setError('');
          handleClose();
        })
        .catch(error => {
          if (error.response.status === 401) {
            setError(error.response.data.message);
          }
          if (error.response.status === 503) {
            setError('Please make sure you entered a correct number');
          }
        });
    }
  };
  return (
    <Dialog
      className="dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '630px',
            height: '100%',
            maxHeight: '480px',
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" className="dialog-nav">
        <span className="dialog-nav__title">Phone update</span>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className="dialog-content">
          <label className="dialog-content__labels">HP Number</label>
          <div className="dialog-content__hp-number">
            <input className="select select-phone" value="+65" />
            <input
              onChange={handlePhoneChange}
              onWheel={e => {
                e.currentTarget.blur();
              }}
              className="number-no-arrows dialog-content__inputs input-width"
              id="demo-helper-text-misaligned-no-helper"
              type="number"
              min="0"
            />
          </div>
          {error && (
            <p className="verify__text" style={{ color: 'red' }}>
              {error}
            </p>
          )}
        </div>
      </DialogContent>
      <DialogActions className="dialog-content__button">
        <button className="apply" onClick={handleSubmit} autoFocus>
          Submit
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default PhoneChange;
