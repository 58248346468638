import { FormControl, FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import React, { useEffect } from 'react';
import NotificationLogo from '../../assests/images/NotificationLogo.svg';
import './ProfileNotifications.css';
import { useRecoilState } from 'recoil';
import { formState } from '../../recoil/formAtom';

const ProfileNotifications = () => {
  const [form, setForm] = useRecoilState(formState);
  useEffect(() => {}, [form]);
  const handleRadioSwitch = e => {
    let res = e.target.value;
    if (form.chosenNotifications.includes(e.target.value)) {
      setForm(prevState => {
        return {
          ...prevState,
          chosenNotifications: prevState.chosenNotifications.filter(el => el !== res),
        };
      });
    } else {
      setForm(prevState => ({
        ...prevState,
        chosenNotifications: [...prevState.chosenNotifications, res],
      }));
    }
  };

  return (
    <div className="notifications">
      <div className="profile__navbars">
        <img src={NotificationLogo} alt="NotificationLogo" />
        <h2 className="profile__title">Notifications</h2>
      </div>
      <div className="profile__container">
        <h3 className="profile__h3">How would you like to receive tuition job notification?</h3>
        <FormControl className="profile__center-fields" component="fieldset">
          <FormGroup aria-label="position" row>
            {form.notifications.map(notification => (
              <FormControlLabel
                value={notification}
                control={<Switch color="primary" />}
                label={notification}
                labelPlacement="start"
                checked={form.chosenNotifications.includes(notification)}
                onChange={handleRadioSwitch}
              />
            ))}
          </FormGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default ProfileNotifications;
