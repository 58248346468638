import React, { useEffect } from 'react';
import './Main.css';
import Table from '../../components/Table';
import Categories from '../../components/Categories';
import Regions from '../../components/Regions';
import { useRecoilState, useRecoilValue } from 'recoil';
import { assignmentsState, categoryState, paginationState, regionsState } from '../../recoil/mainAtoms';
import axios from '../../utils/axios';
import qs from 'qs';
import { authState } from '../../recoil/authAtom';
import { shouldReloadState } from '../../recoil/assignmentAtoms';
import { useNavigate } from 'react-router-dom';
import routes from '../../constants/routes';
import useLogin from '../../store/AuthStore';

const Main = () => {
  const [assignments, setAssignments] = useRecoilState(assignmentsState);
  const [pagination, setPagination] = useRecoilState(paginationState);
  const [subjects, setSubjects] = useRecoilState(categoryState);
  const categories = useRecoilValue(categoryState);
  const regions = useRecoilValue(regionsState);
  const reload = useRecoilValue(shouldReloadState);
  const { auth } = useLogin();
  const navigate = useNavigate();

  useEffect(() => {
    let params;
    if (regions.filters.length > 0) {
      params = {
        pageNumber: pagination.page,
        pageSize: pagination.pageSize,
        chosenCategories: categories.chosenCategories,
        chosenSubjects: categories.chosenSubjects,
        hasAllRegions: false,
        regions: regions.filters,
      };
    } else {
      params = {
        pageNumber: pagination.page,
        pageSize: pagination.pageSize,
        chosenCategories: categories.chosenCategories,
        chosenSubjects: categories.chosenSubjects,
        hasAllRegions: regions.hasAllFilters,
        regions: regions.filters,
      };
    }
    axios
      .get('https://tuitionassignments.sg/api/v1/front/assignments/open', {
        params,
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      })
      .then(data => {
        setAssignments(prevState => ({
          ...prevState,
          assignments: data.data.results,
        }));
        setPagination(prevState => ({
          ...prevState,
          totalPageCount: data.data.totalPages,
        }));
        setSubjects(prevState => ({
          ...prevState,
          categories: data.data.categorySubjectsList,
        }));
      });
  }, [pagination.page, reload, regions.filters, categories.chosenCategories, categories.chosenSubjects]);

  useEffect(() => {
    if (auth) {
      axios.get('profile/update').then(data => {
        let res = data.data.form;
        if (
          !res.name &&
          !res.email &&
          !res.address &&
          !res.experience &&
          !res.highest_qualification &&
          !res.years_of_experience
        ) {
          navigate(routes.profile);
        }
      });
    }
  }, []);
  return (
    <div className="container">
      <Regions />
      <Categories />
      <Table />
    </div>
  );
};

export default Main;
