import PersonLogo from '../../assests/images/PersonLogo.svg';
import DropZone from '../DropZone';
import Button from '@mui/material/Button';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formState } from '../../recoil/formAtom';
import './ProfileBiography.css';
import { useFormContext } from 'react-hook-form';
import PhoneChange from '../PhoneChange';
import PhoneChangeVerify from '../PhoneChangeVerify';
import { phoneChangeState } from '../../recoil/phoneChangeAtom';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import InputAdornment from '@mui/material/InputAdornment';
import EventIcon from '@mui/icons-material/Event';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import IconButton from '@mui/material/IconButton';

import dayjs from 'dayjs';
const base64Header = 'data:image/jpeg;base64,';

const ProfileBiography = () => {
  const [form, setForm] = useRecoilState(formState);
  const [isOtherEnabled, setOther] = useState(false);
  const [gender, setGender] = useState('');
  const [race, setRace] = useState('');
  const [group, setGroup] = useState('');
  const [open, setOpen] = useState('');
  const phoneChange = useRecoilValue(phoneChangeState);

  const [openDatePicker, setOpenDatePicker] = React.useState(false);
  const handleOpenDatePicker = () => setOpenDatePicker(true);
  const handleCloseDatePicker = () => setOpenDatePicker(false);

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    setValue('name', form.name);
    setValue('address', form.address);
    setValue('email', form.email);
    setValue('experience', form.detailsOfExperience);
    setValue('qualification', form.highestQualification);
    setValue('yearsExperience', form.yearsOfExperience);
  }, [
    form.name,
    form.address,
    form.email,
    form.detailsOfExperience,
    form.highestQualification,
    form.yearsOfExperience,
  ]);

  useEffect(() => {
    if (form.chosenRaces.includes(form.otherRace)) {
      setOther(false);
      setValue('other', '');
      setRace(form.otherRace);
    } else {
      setValue('other', form.otherRace);
    }
  }, [form.otherRace]);

  useEffect(() => {
    setGender(form.chosenGenders[0]);
    setRace(form.chosenRaces);
    setGroup(form.chosenGroups[0]);
  }, [form.chosenGroups, form.chosenRaces, form.chosenGenders]);

  useEffect(() => {
    if (form.chosenRaces.includes('Other')) {
      setOther(true);
      setRace('Other');
    } else {
      setOther(false);
      setValue('other', '');
    }
  }, [form.chosenRaces]);

  const handleGenderChange = e => {
    let { value } = e.target;

    setForm(prevState => ({
      ...prevState,
      chosenGenders: [value],
    }));
  };

  const handleRaceChange = e => {
    let { value } = e.target;
    if (value === 'Other') {
      setOther(true);
    } else {
      setOther(false);
      setForm(prevState => ({
        ...prevState,
        otherRace: '',
      }));
    }
    setForm(prevState => ({
      ...prevState,
      chosenRaces: value,
    }));
  };

  const handleGroupChange = e => {
    let { value } = e.target;
    setForm(prevState => ({
      ...prevState,
      chosenGroups: [value],
    }));
  };
  const clearProfilePicture = () => {
    setForm(prevState => ({
      ...prevState,
      profilePicture: '',
    }));
  };

  const handleBirthChange = value => {
    setForm(prevState => ({
      ...prevState,
      birthDate: dayjs(value).format('YYYY-MM-DD'),
    }));
  };

  const updatePhoneNumber = () => {
    setOpen(true);
  };

  return (
    <div className="profile">
      <div className="profile__navbars">
        <img src={PersonLogo} alt="profile_logo" />
        <h1 className="profile__title">Profile</h1>
      </div>
      <div className="form">
        <div className="profile__container">
          <div className="profile__row">
            <div className="profile__dropzone">
              {form.profilePicture && <img src={base64Header + form.profilePicture} alt="#" />}
              {!form.profilePicture && <DropZone loadImage={form.loadImage} />}
              <Button
                disabled={!form.profilePicture}
                variant="contained"
                className="clear-image"
                onClick={clearProfilePicture}
              >
                Remove Picture
              </Button>
            </div>
            <div className="profile__data">
              <div className="birth-phone">
                <TextField
                  className="form__fields phone_field"
                  inputProps={{ readOnly: true }}
                  value={form.hpNumber}
                  placeholder="Phone Number"
                  label="Phone"
                  InputLabelProps={{ shrink: true, style: { color: 'black' } }}
                  id="outlined-required"
                  type="tel"
                />
                <div className="profile-phone-buttons">
                  <Button className="birth-phone__button" onClick={updatePhoneNumber}>
                    Change
                  </Button>
                </div>
                <PhoneChange open={open} handleClose={() => setOpen(false)} />
                <PhoneChangeVerify open={phoneChange.openPhoneChangeVerify} />
              </div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  className="form__fields"
                  id="outlined-required"
                  name="birthday"
                  type="date"
                  label="Birthday"
                  open={openDatePicker}
                  onOpen={handleOpenDatePicker}
                  onClose={handleCloseDatePicker}
                  value={form.birthDate}
                  onChange={handleBirthChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true, style: { color: 'black' } }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton edge="end" onClick={handleOpenDatePicker}>
                              <EventIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              <TextField
                className="form__fields"
                id="name"
                name="name"
                label="Name"
                InputLabelProps={{ shrink: true, style: { color: 'black' } }}
                placeholder="Name"
                {...register('name', { required: 'This field is required' })}
              />
              <span style={{ color: 'red' }}>{errors.name && errors.name.message}</span>
              <TextField
                className="form__fields"
                id="outlined-required"
                placeholder="Email"
                type="email"
                name="email"
                label="Email"
                InputLabelProps={{ shrink: true, style: { color: 'black' } }}
                inputProps={{ novalidate: true }}
                {...register('email', {
                  required: 'This field is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'invalid email address',
                  },
                })}
              />
              <span style={{ color: 'red' }}>{errors.email && errors.email.message}</span>
              <TextField
                className="form__fields"
                id="outlined-required"
                label="Postal code"
                InputLabelProps={{ shrink: true, style: { color: 'black' } }}
                placeholder="Postal code"
                {...register('address', {
                  required: 'This field is required',
                  pattern: { value: /^[0-9]+$/, message: 'This can only be a positive number' },
                  minLength: {
                    value: 6,
                    message: 'Post code is incorrect',
                  },
                  maxLength: {
                    value: 6,
                    message: 'Post code is incorrect',
                  },
                })}
                name="address"
              />
              <span style={{ color: 'red' }}>{errors.address && errors.address.message}</span>
              <TextField
                className="form__fields"
                id="outlined-required"
                label="Highest qualification"
                InputLabelProps={{ shrink: true, style: { color: 'black' } }}
                placeholder="Highest qualification"
                name="qualification"
                {...register('qualification', {
                  required: 'This field is required',
                  maxLength: {
                    value: form.highestQualificationLimit,
                    message: `You can only have ${form.highestQualificationLimit} characters`,
                  },
                })}
              />
              <span style={{ color: 'red' }}>{errors.qualification && errors.qualification.message}</span>
              <TextField
                className="form__fields"
                id="outlined-required"
                label="Years of experience(Enter only digits)"
                InputLabelProps={{ shrink: true, style: { color: 'black' } }}
                placeholder="Years of experience (Enter only digits)"
                name="yearsExperience"
                {...register('yearsExperience', {
                  required: 'This field is required',
                  pattern: { value: /^[0-9]+$/, message: 'This can only be a positive number' },
                })}
              />
              <span style={{ color: 'red' }}>{errors.yearsExperience && errors.yearsExperience.message}</span>
            </div>
          </div>
          <div>
            <textarea
              placeholder="Tell something about your experience"
              name="experience"
              className="textarea"
              {...register('experience', {
                required: 'This field is required',
                maxLength: {
                  value: form.experienceLimit,
                  message: `You can only have ${form.experienceLimit} characters`,
                },
              })}
            />
            <span style={{ color: 'red' }}>{errors.experience && errors.experience.message}</span>
          </div>

          <div className="data-columns">
            <FormControl className="data-column">
              <FormLabel className="data-label" id="demo-controlled-radio-buttons-group">
                Gender:
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="gender"
                value={gender}
                onChange={handleGenderChange}
              >
                {form.gender.map(gender => (
                  <FormControlLabel value={gender} control={<Radio className="radio-btn" />} label={gender} />
                ))}
              </RadioGroup>
            </FormControl>

            <FormControl className="data-column">
              <FormLabel className="data-label" id="demo-controlled-radio-buttons-group">
                Race:
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="race"
                value={race}
                inputRef={register}
                onChange={handleRaceChange}
              >
                {form.race.map(race => (
                  <FormControlLabel value={race} control={<Radio className="radio-btn" />} label={race} />
                ))}
                <input
                  className="input-text"
                  type="text"
                  placeholder="Text"
                  name="other"
                  disabled={!isOtherEnabled}
                  {...register('other')}
                />
              </RadioGroup>
            </FormControl>
            <FormControl className="data-column">
              <FormLabel className="data-label" id="demo-controlled-radio-buttons-group">
                Group:
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="group"
                value={group}
                onChange={handleGroupChange}
              >
                {form.group.map(group => (
                  <FormControlLabel value={group} control={<Radio className="radio-btn" />} label={group} />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBiography;
