import { atom } from 'recoil';

const phoneChangeState = atom({
  key: 'phoneChange',
  default: {
    openPhoneChangeVerify: false,
    number: '',
    prefix: '+65',
  },
});

export { phoneChangeState };
