import { atom } from 'recoil';

const assignmentsState = atom({
  key: 'assignments',
  default: {
    assignments: [],
  },
});

const regionsState = atom({
  key: 'regions',
  default: {
    hasAllFilters: true,
    filters: [],
  },
});
const categoryState = atom({
  key: 'category',
  default: {
    categories: [],
    chosenCategories: [],
    chosenSubjects: [],
  },
});
const paginationState = atom({
  key: 'pagination',
  default: {
    page: 1,
    totalPageCount: 0,
    pageSize: 10,
  },
});

export { assignmentsState, regionsState, categoryState, paginationState };
