import routes from '../../constants/routes';
import firstLogo from '../../assests/images/FirstLogo.svg';
import React, { useState } from 'react';
import axios from '../../utils/axios';
import { useRecoilState } from 'recoil';
import { phoneState, verifyModalState } from '../../recoil/loginAtoms';

const Reset = () => {
  const [phoneDataState, setPhoneDataState] = useRecoilState(phoneState);
  const [verify, setVerify] = useRecoilState(verifyModalState);
  const [error, setError] = useState('');

  const handleForgotSubmit = () => {
    let data = {};
    if (phoneDataState.phoneNumber === '380963323627') {
      data = {
        hp_number: `+${phoneDataState.phoneNumber}`,
      };
    } else {
      data = {
        hp_number: phoneDataState.chosenPrefix + phoneDataState.phoneNumber,
      };
    }
    axios
      .post('auth/reset_password', data)
      .then(data => {
        setVerify(prev => ({
          ...prev,
          shouldOpenModal: true,
          isLogin: true,
          forgotPassword: true,
        }));
      })
      .catch(e => {
        if (e.response.status === 403) {
          setError(e.response.data.message);
        }
      });
  };
  const handlePhoneNumberChange = e => {
    setPhoneDataState(prevState => ({
      ...prevState,
      phoneNumber: e.target.value,
    }));
  };

  return (
    <div className="login-wrapper-main">
      <p className="login-title">Reset Password</p>
      <div className="form-login">
        <label className="dialog-content__labels">HP Number</label>
        <div className="dialog-content__hp-number">
          <img className="dialog-content__number" src={firstLogo} alt="FirstLogo" />
          <input
            className="select select-phone select-phone-min"
            placeholder="+65"
            variant="standard"
            sx={{ m: 1, width: 300, maxWidth: 300 }}
          />
          <input
            onChange={handlePhoneNumberChange}
            onWheel={e => {
              e.currentTarget.blur();
            }}
            className="number-no-arrows dialog-content__inputs input-width transparent"
            id="demo-helper-text-misaligned-no-helper"
            type="number"
            min="0"
          />
        </div>
        <>
          <div className="forgot-password-wrapper">
            <a className="forgot-password" href={routes.login}>
              Login
            </a>
          </div>
        </>
        <div className="div-span">{error && <span className="span-error">{error}</span>}</div>
      </div>
      <button className="login-button" onClick={handleForgotSubmit}>
        Submit
      </button>
    </div>
  );
};

export default Reset;
