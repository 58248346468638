import routes from '../../constants/routes';
import './Login.css';
import firstLogo from '../../assests/images/FirstLogo.svg';
import secondLogo from '../../assests/images/SecondLogo.svg';
import React, { useState } from 'react';
import axios from '../../utils/axios';
import { useRecoilState } from 'recoil';
import { phoneState, verifyModalState } from '../../recoil/loginAtoms';
import { useNavigate } from 'react-router-dom';
import useLogin from '../../store/AuthStore';
import PhoneChangeVerify from '../../components/PhoneChangeVerify';

const Login = () => {
  const [phoneDataState, setPhoneDataState] = useRecoilState(phoneState);
  const [showPassword, setShowPassword] = useState(false);
  const [verify, setVerify] = useRecoilState(verifyModalState);
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  let navigate = useNavigate();

  const handleSubmit = e => {
    e.preventDefault();
    let log = {};
    if (phoneDataState.phoneNumber === '380963323627' || phoneDataState.phoneNumber === '380636011487') {
      log = {
        hp_number: `+${phoneDataState.phoneNumber}`,
        password: password,
      };
    } else {
      log = {
        hp_number: '+65' + phoneDataState.phoneNumber,
        password: password,
      };
    }
    axios
      .post('auth/login', log)
      .then(data => {
        if (data.status === 200) {
          if (data.data.access_token) {
            useLogin.setState({ auth: true, expiresIn: data.data.expires_in, accessToken: data.data.access_token });
            navigate(routes.main);
          }
        }
      })
      .catch(e => {
        if (e.response.status === 403) {
          setError(e.response.data.message);
        }
      });
  };

  const handlePhoneNumberChange = e => {
    setPhoneDataState(prevState => ({
      ...prevState,
      phoneNumber: e.target.value,
    }));
  };

  const handlePasswordChange = ({ target: { value } }) => {
    setPassword(value);
  };

  const togglePasswordShow = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form className="login-wrapper-main" onSubmit={handleSubmit}>
      <p className="login-title">{!verify.forgotPassword ? 'Login' : 'Reset Password'}</p>
      <div className="form-login">
        <label className="dialog-content__labels">HP Number</label>
        <div className="dialog-content__hp-number">
          <img className="dialog-content__number" src={firstLogo} alt="FirstLogo" />
          <input
            className="select select-phone select-phone-min"
            placeholder="+65"
            variant="standard"
            sx={{ m: 1, width: 300, maxWidth: 300 }}
          />
          <input
            onChange={handlePhoneNumberChange}
            onWheel={e => {
              e.currentTarget.blur();
            }}
            className="number-no-arrows dialog-content__inputs input-width transparent"
            id="demo-helper-text-misaligned-no-helper"
            type="number"
            min="0"
          />
        </div>
        <>
          <label className="dialog-content__labels">Password</label>
          <div className="dialog-content__birthdate dialog-content__birthdate--relative">
            <img className="dialog-content__number" src={secondLogo} alt="SecondLogo" />
            <input
              type={showPassword ? 'text' : 'password'}
              className="number-no-arrows dialog-content__inputs dialog-content__inputs--no-margin transparent"
              placeholder="Password"
              id="demo-helper-text-misaligned-no-helper"
              onChange={handlePasswordChange}
            />
            <i
              className={`toggle-password ${showPassword ? 'far fa-eye-slash' : 'far fa-eye'}`}
              id="togglePassword"
              onClick={togglePasswordShow}
            />
          </div>
          <div className="forgot-password-wrapper">
            <a className="forgot-password" href={routes.reset}>
              Forgot password?
            </a>
          </div>
        </>
        <div className="div-span">{error && <span className="span-error">{error}</span>}</div>
      </div>

      <button type="submit" className="login-button">
        Login
      </button>
      <div className="registration-text">
        <span>Not registered? </span>
        <a className="registration-link" href={routes.registration}>
          Create an account
        </a>
      </div>
    </form>
  );
};

export default Login;
