import React, { useState } from 'react';
import './LoginRegister.css';
import personLogo from '../../assests/images/PersonLogo.svg';
import LogReg from '../LogReg';
import { useRecoilState } from 'recoil';
import { verifyModalState } from '../../recoil/loginAtoms';
import routes from '../../constants/routes';
import { NavLink } from 'react-router-dom';

const LoginRegister = () => {
  const [open, setOpen] = useState(false);
  const [verify, setVerify] = useRecoilState(verifyModalState);

  const handleClose = () => {
    setOpen(false);
    setVerify(prev => ({
      ...prev,
      forgotPassword: false,
    }));
  };

  return (
    <>
      <NavLink className="links center-link" activeclassname="is-active" to={routes.login}>
        Login
        <img className="button-logo" src={personLogo} alt="personLogo" />
      </NavLink>

      <LogReg open={open} handleClose={handleClose} />
    </>
  );
};

export default LoginRegister;
