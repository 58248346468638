import React, { useEffect } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import LocationLogo from '../../assests/images/LocationLogo.svg';
import { useRecoilState } from 'recoil';
import { formState } from '../../recoil/formAtom';

const ProfileLocation = () => {
  const [form, setForm] = useRecoilState(formState);
  useEffect(() => {}, [form]);
  const handleCheckbox = e => {
    let res = e.target.value;
    if (form.chosenLocations.includes(e.target.value)) {
      setForm(prevState => {
        return {
          ...prevState,
          chosenLocations: prevState.chosenLocations.filter(el => el !== res),
        };
      });
    } else {
      setForm(prevState => ({
        ...prevState,
        chosenLocations: [...prevState.chosenLocations, res],
      }));
    }
  };

  return (
    <div className="location">
      <div className="profile__navbars">
        <img src={LocationLogo} alt="LocationLogo" />
        <h2 className="profile__title">Location</h2>
      </div>
      <div className="profile__container">
        <h3 className="profile__h3">
          Where is your preferred tuition location? You will receive tuition notification based on your selection
        </h3>
        <div className="check-boxes">
          <FormGroup>
            {form.locations.map(location => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.chosenLocations.includes(location)}
                    onChange={handleCheckbox}
                    value={location}
                    style={{
                      color: '#3653B7',
                    }}
                    className="checkbox"
                  />
                }
                label={location}
              />
            ))}
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default ProfileLocation;
