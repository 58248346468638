import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import routes from '../../constants/routes';
import Main from '../../routes/Main';
import Profile from '../../routes/Profile';
import React, { useEffect } from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Login from '../../routes/Login';
import Registration from '../../routes/Registration';
import Reset from '../../routes/Reset';
import useLogin from '../../store/AuthStore';
import axios from '../../utils/axios';

const NavRoutes = () => {
  const { auth } = useLogin();

  const ProtectedRoute = ({ children }) => {
    if (!auth) {
      return <Navigate to={routes.main} replace />;
    }
    return children;
  };

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path={routes.main} element={<Main />} />
        <Route path="*" element={<Main />} />
        <Route path="" element={<Main />} />
        <Route path={routes.login} element={<Login />} />
        <Route path={routes.reset} element={<Reset />} />
        <Route path={routes.registration} element={<Registration />} />
        <Route
          path={routes.profile}
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
      </Routes>
      <Footer />
    </Router>
  );
};

export default NavRoutes;
