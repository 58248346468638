import React, { useEffect, useState } from 'react';
import './Profile.css';
import Button from '@mui/material/Button';
import axios from '../../utils/axios';
import { useRecoilState } from 'recoil';
import { formState } from '../../recoil/formAtom';
import ProfileBiography from '../../components/ProfileBiography';
import ProfileTutoringPreferences from '../../components/ProfileTutoringPreferences';
import ProfileLocation from '../../components/ProfileLocation';
import ProfileNotifications from '../../components/ProfileNotifications';
import { useForm, FormProvider } from 'react-hook-form';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import loginLogo from '../../assests/images/LoginLogo.svg';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DeleteModal from '../../components/DeleteModal';

const Profile = () => {
  const [form, setForm] = useRecoilState(formState);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    axios.get('profile/update').then(data => {
      let res = data.data.form;
      let picture = '';
      if (res.profile_picture) {
        if (
          !res.profile_picture.includes(
            'iVBORw0KGgoAAAANSUhEUgAAAwAAAAMACAYAAACTgQCOAACAAElEQVR4Xuy9aawlZ56nNVU9zTA9TAvohg8jDTQgRho1A2IGsYzmw4hNAjESwybUQogPCAkJ1AgQCL5wVXlv3i33TKedtnO10',
          )
        ) {
          picture = res.profile_picture;
        }
      }
      setForm(prevState => ({
        ...prevState,
        name: res.name,
        email: res.email,
        address: res.address,
        birthDate: res.birth_date,
        hpNumber: res.hp_number,
        detailsOfExperience: res.experience,
        highestQualification: res.highest_qualification,
        yearsOfExperience: res.years_of_experience,
        gender: res.gender,
        race: res.races,
        otherRace: res.other_race,
        group: res.group,
        categories: res.categories,
        locations: res.locations,
        notifications: res.notifications,
        profilePicture: picture,
        chosenProfilePicture: '',
        chosenCategories: res.chosenCategories,
        chosenGenders: res.chosenGenders,
        chosenGroups: res.chosenGroups,
        chosenLocations: res.chosenLocations,
        chosenNotifications: res.chosenNotifications,
        chosenRaces: res.chosenRaces,
        experienceLimit: res.experienceLimit,
        highestQualificationLimit: res.highestQualificationLimit,
      }));
    });
  }, []);

  const onSubmit = data => {
    const body = {
      birthDate: form.birthDate,
      chosenProfilePicture: form.chosenProfilePicture,
      name: data.name,
      email: data.email,
      address: data.address,
      highestQualification: data.qualification,
      yearsOfExperience: data.yearsExperience,
      detailsOfExperience: data.experience,
      chosenGenders: form.chosenGenders,
      chosenRaces: form.chosenRaces,
      otherRace: data.other,
      chosenGroups: form.chosenGroups,
      chosenCategories: form.chosenCategories,
      chosenLocations: form.chosenLocations,
      chosenNotifications: form.chosenNotifications,
    };
    axios.post('profile/update', body).then(data => {
      setOpen(true);
    });
  };

  const handleDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const methods = useForm();
  return (
    <div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
          <div className="container">
            <ProfileBiography />
            <ProfileTutoringPreferences />
            <ProfileLocation />
            <ProfileNotifications />
            <div className="save-region">
              <Button onClick={handleDelete} variant="contained" className="save-button delete-button">
                Delete Profile
              </Button>
              <Button type="submit" variant="contained" className="save-button">
                Save Profile
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
      <DeleteModal open={openDelete} handleClose={handleCloseDelete} />
      <SubmitModal open={open} handleClose={handleClose} />
    </div>
  );
};

const SubmitModal = ({ open, handleClose }) => {
  return (
    <div className="">
      <Dialog
        className="dialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '630px',
              height: '100%',
              maxHeight: '480px',
            },
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" className="dialog-nav">
          <img className="login-icon" src={loginLogo} alt="loginLogo" />

          <span className="dialog-nav__title">Profile Saved</span>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog_message">
          <h2>We have saved your profile</h2>
        </DialogContent>
        <DialogActions className="dialog-content__button">
          <button className="apply" onClick={handleClose} autoFocus>
            Close
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Profile;
