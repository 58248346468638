import axios from 'axios';
import configData from '../config.json';
import useLogin from '../store/AuthStore';
import routes from '../constants/routes';

const api = axios.create({
  baseURL: configData.serverUrl,
});
const token = useLogin.getState().accessToken;

if (token) {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log(error.response);
    if (error.response.status === 401) {
      if (
        error.response.data.message === 'Unauthorized' ||
        error.response.data.message === 'Access token expired. Please log in again.'
      ) {
        useLogin.setState({ auth: false, expiresIn: 0, accessToken: '' });
        window.location.replace(routes.main);
      }
      throw error;
    }
    throw error;
  },
);

export default api;
