import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { regionsState } from '../../recoil/mainAtoms';

const Regions = () => {
  const [regions, setRegions] = useRecoilState(regionsState);
  const regionNames = ['Central', 'East', 'West', 'North', 'North-East'];
  const [selectRegions, setSelectRegions] = React.useState(['all']);

  const handleClick = value => {
    if (regions.filters.includes(value)) {
      setRegions(prevState => {
        return {
          ...prevState,
          filters: prevState.filters.filter(el => el !== value),
        };
      });
    } else {
      setRegions(prevState => ({
        ...prevState,
        filters: [...prevState.filters, value],
      }));
    }
  };

  const allRegionHandler = () => {
    setRegions({
      filters: [],
      hasAllFilters: true,
    });
  };

  useEffect(() => {
    if (regions.hasAllFilters) {
      setRegions(prevState => ({
        ...prevState,
        filters: [],
      }));
    }
  }, [regions.hasAllFilters]);

  useEffect(() => {
    if (regions.filters.length === 0 || regions.filters.length === 5) {
      setRegions({
        hasAllFilters: true,
        filters: [],
      });
    } else {
      setRegions(prevState => ({
        ...prevState,
        hasAllFilters: false,
      }));
    }
  }, [regions.filters.length]);

  useEffect(() => {
    if (selectRegions.includes('all') && selectRegions.length === 1) {
      setRegions({
        filters: [],
        hasAllFilters: true,
      });
    }
    if (selectRegions.length === 0) {
      setRegions({
        filters: [],
        hasAllFilters: true,
      });
      setSelectRegions(['all']);
    }
    if (selectRegions.length === regionNames.length) {
      setRegions({
        filters: [],
        hasAllFilters: true,
      });
      setSelectRegions(['all']);
    }
    if (selectRegions[0] === 'all' && selectRegions.length > 1) {
      setRegions({
        filters: selectRegions.filter(e => e !== 'all'),
        hasAllFilters: false,
      });
      setSelectRegions(prev => prev.filter(e => e !== 'all'));
    }
    if (selectRegions.includes('all') && selectRegions[0] !== 'all') {
      setRegions({
        filters: [],
        hasAllFilters: true,
      });
      setSelectRegions(['all']);
    }
  }, [selectRegions.length]);

  const handleRegionChangeMobile = ({ target: { value } }) => {
    setSelectRegions(value);
  };

  return (
    <>
      <FormControl className="mobile-select" variant="standard" sx={{ m: 1, width: 300, maxWidth: 300 }}>
        <InputLabel id="demo-multiple-name-label">All regions</InputLabel>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          defaultValue="all"
          value={selectRegions}
          onChange={handleRegionChangeMobile}
        >
          <MenuItem value="all">All Regions</MenuItem>
          {regionNames.map(name => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className="filter-buttons">
        <button
          className={`filter-button  ${regions.hasAllFilters ? 'active' : ''}`}
          onClick={() => allRegionHandler()}
        >
          All regions
        </button>
        {regionNames.map(name => (
          <button
            key={name}
            className={`filter-button ${regions.filters.includes(name) ? 'active' : ''}`}
            onClick={() => handleClick(name)}
          >
            {name}
          </button>
        ))}
      </div>
    </>
  );
};

export default Regions;
