import React, { useCallback, useMemo, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import axios from '../../utils/axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import loginLogo from '../../assests/images/LoginLogo.svg';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formState } from '../../recoil/formAtom';
import { dateState, phoneState } from '../../recoil/loginAtoms';
import { phoneChangeState } from '../../recoil/phoneChangeAtom';
import useLogin from '../../store/AuthStore';

const MemoCountdown = React.memo(({ onComplete }) => {
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      onComplete();
    }
    return (
      <span>
        {minutes}:{seconds}
      </span>
    );
  };
  return <Countdown className="time" date={Date.now() + 300000} renderer={renderer} />;
});

const PhoneChangeVerify = ({ open }) => {
  const [form, setForm] = useRecoilState(formState);

  const verifyNumber = useRecoilValue(phoneState);
  const dateDataState = useRecoilValue(dateState);
  const [error, setError] = useState();
  const [codeState, setCodeState] = useState([]);
  const [sendAgain, setSendAgain] = useState(false);
  const [phoneChange, setPhoneChange] = useRecoilState(phoneChangeState);

  const inputsRef = useRef([]);
  const codeArray = useMemo(() => Array(4).fill(null), []);

  const handleClose = () => {
    setPhoneChange(prev => ({ ...prev, number: '', openPhoneChangeVerify: false }));
  };

  const codeHandler = idx => e => {
    setError('');
    if (e.key !== 'Backspace') {
      if (idx !== codeArray.length - 1) {
        inputsRef.current[idx + 1].focus();
      }
    } else {
      if (idx !== 0) {
        inputsRef.current[idx - 1].focus();
      }
    }
    setCodeState(prev => {
      prev[idx] = e.target.value;
      return [...prev];
    });
  };

  const handleSendCodeAgain = () => {
    const data = {
      hp_number: verifyNumber.chosenPrefix + verifyNumber.phoneNumber,
    };
    axios.post('auth/retry', data);
    setSendAgain(false);
  };

  const onComplete = useCallback(() => {
    setSendAgain(true);
  }, [setSendAgain]);

  const handleSubmit = () => {
    setError('');
    axios
      .post('profile/update/hp_number/validate', {
        hp_number: phoneChange.number,
        code: codeState.join(''),
      })
      .then(data => {
        setForm(prev => ({
          ...prev,
          hpNumber: phoneChange.number,
        }));
        handleClose();
      })
      .catch(error => {
        if (error.response.status === 401) {
          setError(error.response.data.message);
        }
      });
  };

  return (
    <Dialog
      className="dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '630px',
            height: '100%',
            maxHeight: '480px',
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" className="dialog-nav">
        <img className="login-icon" src={loginLogo} alt="loginLogo" />
        Phone change verify
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          padding: '30px 30px',
        }}
      >
        <div className="dialog-content verify" style={{ marginTop: '1em' }}>
          <div className="verify__inputs">
            <form>
              {codeArray.map((_, idx) => (
                <input
                  style={{ marginTop: '4rem' }}
                  ref={htmlElement => {
                    inputsRef.current[idx] = htmlElement;
                  }}
                  key={idx}
                  className="dialog-content__inputs verify__input"
                  placeholder="*"
                  id="demo-helper-text-misaligned-no-helper"
                  onKeyUp={codeHandler(idx)}
                  maxLength="1"
                />
              ))}
            </form>
          </div>
          <p className="verify__text">Please enter the code that is send to your phone</p>
          {error && (
            <p className="verify__text" style={{ color: 'red' }}>
              {error}
            </p>
          )}

          <div className="countdown-time">
            <button className="send-again-button" disabled={!sendAgain} onClick={handleSendCodeAgain} autoFocus>
              Send code again&nbsp;
            </button>
            {!sendAgain && <MemoCountdown onComplete={onComplete} />}
          </div>
        </div>
      </DialogContent>
      <DialogActions className="dialog-content__button verify__buttons">
        <div className="button_countdown">
          <button className="apply verify__apply-btn" onClick={handleSubmit} autoFocus>
            Verify
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default PhoneChangeVerify;
