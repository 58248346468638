import create from 'zustand';
import { persist } from 'zustand/middleware';
import api from '../utils/axios';

const useLogin = create(
  persist(
    set => ({
      auth: false,
      expiresIn: 0,
      accessToken: '',
      logout: () => set({ auth: false, expiresIn: 0, accessToken: '' }),
    }),
    {
      name: 'auth-storage',
      getStorage: () => localStorage,
    },
  ),
);

useLogin.subscribe(({ accessToken }) => {
  if (accessToken) {
    api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  } else {
    delete api.defaults.headers.common.Authorization;
  }
});

export default useLogin;
