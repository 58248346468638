import { atom } from 'recoil';

const assignmentState = atom({
  key: 'assignment',
  default: {
    all_groups: '',
    all_hourly_rates: '',
    day_and_time: '',
    details_of_experience: '',
    group: '',
    highest_qualification: '',
    years_of_experience: '',
  },
});

const shouldReloadState = atom({
  key: 'reload',
  default: {
    shouldReload: false,
  },
});
export { assignmentState, shouldReloadState };
