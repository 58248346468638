import { atom } from 'recoil';

const phoneState = atom({
  key: 'phone',
  default: {
    chosenPrefix: '',
    phoneNumber: '',
  },
});

const dateState = atom({
  key: 'date',
  default: {
    DD: '',
    MM: '',
    YYYY: '',
  },
});

const verifyModalState = atom({
  key: 'isOpening',
  default: {
    shouldOpenModal: false,
    isLogin: false,
    forgotPassword: false,
    password: '',
  },
});

export { phoneState, dateState, verifyModalState };
