import React from 'react';
import { Checkbox, FormControl, FormGroup, FormLabel } from '@material-ui/core';
import { FormControlLabel } from '@mui/material';
import './ProfileTutoringPreferences.css';
import HatLogo from '../../assests/images/HatLogo.svg';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formState } from '../../recoil/formAtom';

const Category = ({ name, subjects }) => {
  return (
    <FormControl className="form-controls">
      <FormLabel className="form-controls__label" id="demo-controlled-radio-buttons-group">
        {name} :{' '}
      </FormLabel>
      <SubjectList subjects={subjects} categoryName={name} />
    </FormControl>
  );
};

const CategoryList = ({ categories = [] }) => {
  return <>{categories.map(Category)}</>;
};

const Subject = ({ name = '', categoryName }) => {
  const [form, setForm] = useRecoilState(formState);
  const { chosenCategories } = form;
  const chosenCategory = chosenCategories.find(chosenCategory => chosenCategory.name === categoryName);

  let isChecked = false;

  if (chosenCategory) {
    isChecked = chosenCategory.subjects.includes(name);
  }

  const handleCheckBox = e => {
    let { checked } = e.target;
    let chosenCategories;
    const existingCategory = form.chosenCategories.find(chosenCategory => chosenCategory.name === categoryName);
    if (checked) {
      if (existingCategory) {
        chosenCategories = form.chosenCategories.map(chosenCategory =>
          chosenCategory.name === categoryName
            ? { ...chosenCategory, subjects: [...chosenCategory.subjects, name] }
            : chosenCategory,
        );
      } else {
        chosenCategories = [...form.chosenCategories, { name: categoryName, subjects: [name] }];
      }
    } else {
      chosenCategories = form.chosenCategories.map(chosenCategory =>
        chosenCategory.name === categoryName
          ? { ...chosenCategory, subjects: chosenCategory.subjects.filter(value => value !== name) }
          : chosenCategory,
      );
    }
    chosenCategories = chosenCategories.filter(chosenCategory => chosenCategory.subjects.length);
    setForm(prevState => ({
      ...prevState,
      chosenCategories,
    }));
  };
  return (
    <FormControlLabel
      control={
        <Checkbox
          value={name}
          style={{
            color: '#3653B7',
          }}
          className="checkbox"
          checked={isChecked}
          onChange={handleCheckBox}
        />
      }
      label={name}
    />
  );
};

const SubjectList = ({ subjects, categoryName }) => {
  return (
    <FormGroup>
      <div className="form-bg">
        {subjects.map(subject => (
          <Subject {...subject} categoryName={categoryName} />
        ))}
      </div>
    </FormGroup>
  );
};

const ProfileTutoringPreferences = () => {
  const form = useRecoilValue(formState);

  return (
    <div className="tutoring-preferences">
      <div className="profile__navbars">
        <img src={HatLogo} alt="hat_logo" />
        <h2 className="profile__title">Tutoring Preferences</h2>
      </div>
      <div className="profile__container">
        <h3 className="profile__h3">
          Please choose the subject which you can teach. You will receive tuition notification based on your selection
        </h3>
        <CategoryList categories={form.categories} />
      </div>
    </div>
  );
};
export default ProfileTutoringPreferences;
