import React, { useMemo } from 'react';
import { useTable } from 'react-table';
import { COLUMNS } from './Columns';
import { Pagination } from '@mui/material';
import { useRecoilState } from 'recoil';
import { assignmentsState, paginationState } from '../../recoil/mainAtoms';
import { Table as ResponsiveTable, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './Table.css';

const Table = () => {
  const [pagination, setPagination] = useRecoilState(paginationState);
  const [assignments] = useRecoilState(assignmentsState);

  const handlePagination = event => {
    setPagination(prevState => ({
      ...prevState,
      page: event.target.textContent,
    }));
  };

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => assignments.assignments, [assignments.assignments]);

  const tableInstance = useTable({
    columns,
    data,
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
    <div className="table-wrapper">
      <ResponsiveTable className="table-content" {...getTableProps()}>
        <Thead>
          <>
            {headerGroups.map(headerGroup => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <Th {...column.getHeaderProps()}>{column.render('Header')}</Th>
                ))}
              </Tr>
            ))}
          </>
        </Thead>
        <Tbody {...getTableBodyProps}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map(cell => (
                  <Td className="cell-text" {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </ResponsiveTable>
      <Pagination
        className="pagination"
        count={pagination.totalPageCount}
        color="primary"
        onChange={handlePagination}
      />
    </div>
  );
};

export default Table;
