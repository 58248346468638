import Button from '@mui/material/Button';
import ApplyModal from '../ApplyModal';
import React, { useState } from 'react';
import calendarBlueLogo from '../../assests/images/calendarBlueLogo.svg';
import hatBlueLogo from '../../assests/images/hatBlueLogo.svg';
import clockBlueLogo from '../../assests/images/clockBlueLogo.svg';
import dollarBlueLogo from '../../assests/images/dollarBlueLogo.svg';
import locationBlueLogo from '../../assests/images/locationBlueLogo.svg';
import profileBlueLogo from '../../assests/images/profileBlueLogo.svg';
import sevenBlueLogo from '../../assests/images/sevenBlueLogo.svg';
import './Columns.css';
import useLogin from '../../store/AuthStore';
import { useNavigate } from 'react-router-dom';
import routes from '../../constants/routes';
export const COLUMNS = [
  {
    Header: 'Date(Code)',
    accessor: 'date',
    Cell: ({ row }) => {
      return (
        <>
          <div className="table-icons">
            <img className="responsive-logo" src={clockBlueLogo} alt="#" />
            <p>{row.original.date}</p>
          </div>
          <div className="table-code">
            <p>({row.original.assignment_id})</p>
          </div>
        </>
      );
    },
  },
  {
    Header: 'Preference',
    accessor: 'preference',
    Cell: ({ row }) => {
      return (
        <>
          <div className="table-icons">
            <img className="responsive-logo" src={profileBlueLogo} alt="#" />
            <p>{row.original.preference}</p>
          </div>
        </>
      );
    },
  },
  {
    Header: 'Subject',
    accessor: 'subject',
    Cell: ({ row }) => {
      return (
        <>
          <div className="table-icons">
            <img className="responsive-logo" src={hatBlueLogo} alt="#" />
            <p>{row.original.subject}</p>
          </div>
        </>
      );
    },
  },
  {
    Header: 'Location',
    accessor: 'location',
    Cell: ({ row }) => {
      let link = `https://www.google.com/maps/search/{${row.original.location}}`;
      return (
        <div className="table-icons">
          <img className="responsive-logo" src={locationBlueLogo} alt="#" />
          <a href={link}>{row.original.location}</a>
          <p>{}</p>
        </div>
      );
    },
  },
  {
    Header: 'Time',
    accessor: 'time',
    Cell: ({ row }) => {
      return (
        <div className="table-icons">
          <img className="responsive-logo" src={calendarBlueLogo} alt="#" />
          <p>{row.original.time}</p>
        </div>
      );
    },
  },
  {
    Header: 'Rate',
    accessor: 'rate',
    Cell: ({ row }) => {
      return (
        <div className="table-icons">
          <img className="responsive-logo" src={dollarBlueLogo} alt="#" />
          <p>{row.original.rate}</p>
        </div>
      );
    },
  },
  {
    Header: 'Frequency',
    accessor: 'frequency',
    Cell: ({ row }) => {
      return (
        <>
          <div className="table-icons">
            <img className="responsive-logo" src={sevenBlueLogo} alt="#" />
            <p>{row.original.frequency}</p>
          </div>
        </>
      );
    },
  },
  {
    Header: 'Commission',
    accessor: 'commission',
    Cell: ({ row }) => {
      const [open, setOpen] = useState(false);
      const { auth } = useLogin();
      let navigate = useNavigate();

      const isApplied = row.original.applied;

      const openModal = () => {
        if (auth) {
          setOpen(true);
        } else {
          navigate(routes.login);
        }
      };

      const onClose = () => {
        setOpen(false);
      };

      return (
        <>
          <p className="grey-text">{row.original.commission}</p>
          {isApplied ? (
            <p className="applied-assignment">Applied</p>
          ) : (
            <Button onClick={openModal} className="apply-buttons">
              Apply now
            </Button>
          )}
          <ApplyModal open={open} onClose={onClose} tuitionData={row.original} />
        </>
      );
    },
  },
];
