import { atom } from 'recoil';

const formState = atom({
  key: 'form',
  default: {
    name: '',
    email: '',
    address: '',
    birthDate: null,
    hpNumber: '',
    detailsOfExperience: '',
    highestQualification: '',
    yearsOfExperience: '',
    gender: [],
    race: [],
    otherRace: null,
    group: [],
    categories: [],
    locations: [],
    notifications: [],
    profilePicture: '',
    chosenCategories: [],
    chosenGenders: [],
    chosenGroups: [],
    chosenLocations: [],
    chosenNotifications: [],
    chosenRaces: [],
    experienceLimit: '',
    highestQualificationLimit: '',
  },
});

export { formState };
