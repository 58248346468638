import React, { useState } from 'react';
import { AppBar } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import routes from '../../constants/routes';
import LoginRegister from '../LoginRegister';
import './Navbar.css';
import logoutLogo from '../../assests/images/LogoutLogo.svg';
import { useRecoilState } from 'recoil';
import Verify from '../VerifyModal';
import { verifyModalState } from '../../recoil/loginAtoms';
import useLogin from '../../store/AuthStore';
import personLogo from '../../assests/images/PersonLogo.svg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [verify, setVerify] = useRecoilState(verifyModalState);
  const { auth, logout } = useLogin();

  const handleClose = () => {
    setVerify({
      shouldOpenModal: false,
      isLogin: false,
    });
  };
  return (
    <AppBar className="nav-bar" position="relative">
      <div className="container">
        <div className={'nav-bar__pages ' + (isOpen ? 'open' : '')}>
          <NavLink className="links" activeclassname="is-active" to={routes.main}>
            SG Tuition Assignments
          </NavLink>
          {auth && (
            <NavLink className="links" activeclassname="is-active" to={routes.profile}>
              Profile
            </NavLink>
          )}
          <Verify open={verify.shouldOpenModal} handleClose={handleClose} />
          <div className="login-wrapper">
            {auth ? (
              <div className="logout" onClick={logout}>
                <p className="logout__text">Logout</p>
                <img src={logoutLogo} alt="Logout Logo" />
              </div>
            ) : (
              <NavLink className="links center-link" activeclassname="is-active" to={routes.login}>
                Login
                <img className="button-logo" src={personLogo} alt="personLogo" />
              </NavLink>
            )}
          </div>
        </div>
        <div
          className={'burger-menu ' + (isOpen ? 'open' : '')}
          onClick={() => {
            setIsOpen(prevState => !prevState);
          }}
        >
          <span className="burger-menu__line"></span>
          <span className="burger-menu__line"></span>
          <span className="burger-menu__line"></span>
        </div>
      </div>
    </AppBar>
  );
};

export default Navbar;
