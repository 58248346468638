import React, { useCallback, useMemo, useRef, useState } from 'react';
import Countdown from 'react-countdown';
import axios from '../../utils/axios';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import loginLogo from '../../assests/images/LoginLogo.svg';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { useRecoilState, useRecoilValue } from 'recoil';
import { formState } from '../../recoil/formAtom';
import { dateState, phoneState, verifyModalState } from '../../recoil/loginAtoms';
import routes from '../../constants/routes';
import { useNavigate } from 'react-router-dom';
import useLogin from '../../store/AuthStore';
import './VerifyModal.css';

const MemoCountdown = React.memo(({ onComplete }) => {
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      onComplete();
    }
    return (
      <span>
        {minutes}:{seconds}
      </span>
    );
  };
  return <Countdown className="time" date={Date.now() + 300000} renderer={renderer} />;
});

const Verify = ({ open, handleClose, login }) => {
  const number = useRecoilValue(formState);
  const verifyNumber = useRecoilValue(phoneState);
  const [verify, setVerify] = useRecoilState(verifyModalState);
  const [error, setError] = useState();
  const dateDataState = useRecoilValue(dateState);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  let navigate = useNavigate();

  React.useEffect(() => {
    setError('');
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    if (verify.isLogin) {
      sendVerify('auth/verify');
    } else {
      sendValidate('profile/update/hp_number/validate');
    }
  };

  const handleForgotSubmit = e => {
    e.preventDefault();
    console.log('I am here');
    let body = {};
    if (verifyNumber.phoneNumber === '380963323627') {
      body = {
        new_password: password,
        hp_number: `+${verifyNumber.phoneNumber}`,
        code: codeState.join(''),
      };
    } else {
      body = {
        new_password: password,
        hp_number: verifyNumber.chosenPrefix + verifyNumber.phoneNumber,
        code: codeState.join(''),
      };
    }
    axios.post('auth/set_password', body).then(e => {
      console.log('executed call', e);
    });
    debugger;
    setVerify(prev => ({
      ...prev,
      forgotPassword: false,
    }));
  };

  const sendVerify = url => {
    let log = {};
    console.log(verify.password);
    if (verifyNumber.phoneNumber === '380963323627') {
      log = {
        hp_number: `+${verifyNumber.phoneNumber}`,
        code: codeState.join(''),
        password: verify.password,
      };
    } else {
      log = {
        hp_number: verifyNumber.chosenPrefix + verifyNumber.phoneNumber,
        code: codeState.join(''),
        password: verify.password,
      };
    }
    axios
      .post(url, log)
      .then(data => {
        if (data.data.access_token) {
          localStorage.setItem('token', data.data.access_token);
          setError('');
          useLogin.setState({ auth: true, expiresIn: data.data.expires_in, accessToken: data.data.access_token });
          navigate(routes.profile);
          handleClose();
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          setError(error.response.data.message);
        }
      });
  };

  const sendValidate = url => {
    axios
      .post(url, {
        hp_number: number.hpNumber,
        code: codeState.join(''),
      })
      .then(data => {
        useLogin.setState({ auth: true, expiresIn: data.data.expires_in, accessToken: data.data.access_token });
        handleClose();
      });
  };

  const codeArray = useMemo(() => Array(4).fill(null), []);
  const inputsRef = useRef([]);
  const [codeState, setCodeState] = useState([]);
  const [sendAgain, setSendAgain] = useState(false);

  const codeHandler = idx => e => {
    if (e.key !== 'Backspace') {
      if (idx !== codeArray.length - 1) {
        inputsRef.current[idx + 1].focus();
      }
    } else {
      if (idx !== 0) {
        inputsRef.current[idx - 1].focus();
      }
    }
    setCodeState(prev => {
      prev[idx] = e.target.value;
      return [...prev];
    });
  };

  const handleSendCodeAgain = () => {
    const data = {
      hp_number: verifyNumber.chosenPrefix + verifyNumber.phoneNumber,
    };
    axios.post('auth/retry', data);
    setSendAgain(false);
  };

  const onComplete = useCallback(() => {
    setSendAgain(true);
  }, [setSendAgain]);
  const handlePasswordChange = ({ target: { value } }) => {
    setPassword(value);
  };
  const togglePasswordShow = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Dialog
      className="dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '630px',
            height: '100%',
            maxHeight: '480px',
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" className="dialog-nav">
        <img className="login-icon" src={loginLogo} alt="loginLogo" />
        Login/Register
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          padding: '30px 30px',
        }}
      >
        <form onSubmit={verify.forgotPassword ? handleForgotSubmit : handleSubmit}>
          <div className="dialog-content verify" style={{ marginTop: '1em' }}>
            <div className="verify__inputs">
              {verify.forgotPassword && (
                <div className="dialog-content__birthdate">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="number-no-arrows dialog-content__inputs"
                    placeholder="Password"
                    id="demo-helper-text-misaligned-no-helper"
                    onChange={handlePasswordChange}
                  />
                </div>
              )}

              {codeArray.map((_, idx) => (
                <input
                  style={{ marginTop: '4rem' }}
                  ref={htmlElement => {
                    inputsRef.current[idx] = htmlElement;
                  }}
                  key={idx}
                  className="dialog-content__inputs verify__input"
                  placeholder="*"
                  id="demo-helper-text-misaligned-no-helper"
                  onKeyUp={codeHandler(idx)}
                  maxLength="1"
                />
              ))}
            </div>
            {verify.forgotPassword ? (
              <p className="verify__text">Please enter new password and the code that is send to your phone</p>
            ) : (
              <p className="verify__text">Please enter the code that is send to your phone</p>
            )}
            {error && (
              <p className="verify__text" style={{ color: 'red' }}>
                {error}
              </p>
            )}

            <div className="countdown-time">
              <button className="send-again-button" disabled={!sendAgain} onClick={handleSendCodeAgain} autoFocus>
                Send code again&nbsp;
              </button>
              {!sendAgain && <MemoCountdown onComplete={onComplete} />}
            </div>
            <div className="verify-submit">
              <button className="apply verify__apply-btn verify-submit__submit" type="submit">
                {verify.forgotPassword ? 'Reset Password' : 'Verify'}
              </button>
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions className="dialog-content__button verify__buttons">
        <div className="button_countdown"></div>
      </DialogActions>
    </Dialog>
  );
};

export default Verify;
