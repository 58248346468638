import routes from '../../constants/routes';
import firstLogo from '../../assests/images/FirstLogo.svg';
import secondLogo from '../../assests/images/SecondLogo.svg';
import React, { useState } from 'react';
import axios from '../../utils/axios';
import { useRecoilState } from 'recoil';
import { phoneState, verifyModalState } from '../../recoil/loginAtoms';
import { useForm } from 'react-hook-form';

const Registration = () => {
  const [phoneDataState, setPhoneDataState] = useRecoilState(phoneState);
  const [verify, setVerify] = useRecoilState(verifyModalState);
  const [spanError, setSpanError] = useState('');

  const handleRegister = formData => {
    let log = {};
    if (phoneDataState.phoneNumber === '380963323627' || phoneDataState.phoneNumber === '380636011487') {
      log = {
        hp_number: `+${phoneDataState.phoneNumber}`,
      };
    } else {
      log = {
        hp_number: '+65' + phoneDataState.phoneNumber,
      };
    }
    axios
      .post('auth/register', log)
      .then(data => {
        if (data.status === 200) {
          setVerify(prev => ({
            ...prev,
            shouldOpenModal: true,
            isLogin: true,
            password: formData.password,
          }));
        }
      })
      .catch(e => {
        if (e.response.status === 403) {
          setSpanError(e.response.data.message);
        }
      });
  };

  const handlePhoneNumberChange = e => {
    setPhoneDataState(prevState => ({
      ...prevState,
      phoneNumber: e.target.value,
    }));
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  return (
    <form className="login-wrapper-main" onSubmit={handleSubmit(handleRegister)}>
      <p className="login-title">Registration</p>
      <div className="form-login">
        <label className="dialog-content__labels">HP Number</label>
        <div className="dialog-content__hp-number">
          <img className="dialog-content__number" src={firstLogo} alt="FirstLogo" />
          <input
            className="select select-phone select-phone-min"
            placeholder="+65"
            variant="standard"
            sx={{ m: 1, width: 300, maxWidth: 300 }}
          />
          <input
            onChange={handlePhoneNumberChange}
            onWheel={e => {
              e.currentTarget.blur();
            }}
            className="number-no-arrows dialog-content__inputs input-width transparent"
            id="demo-helper-text-misaligned-no-helper"
            type="number"
            min="0"
          />
        </div>
        <>
          <div className="dialog-content__birthdate dialog-content__birthdate--relative">
            <img className="dialog-content__number" src={secondLogo} alt="SecondLogo" />
            <input
              type="password"
              className="number-no-arrows dialog-content__inputs dialog-content__inputs--no-margin transparent"
              placeholder="Password"
              id="demo-helper-text-misaligned-no-helper"
              {...register('password', {
                required: 'You must specify a password',
                minLength: {
                  value: 8,
                  message: 'Password must have at least 8 characters',
                },
              })}
            />

            {errors.password && <span style={{ color: 'red' }}>{errors.password.message}</span>}
          </div>
        </>
        <>
          <div className="dialog-content__birthdate dialog-content__birthdate--relative">
            <img className="dialog-content__number" src={secondLogo} alt="SecondLogo" />
            <input
              type="password"
              className="number-no-arrows dialog-content__inputs dialog-content__inputs--no-margin transparent"
              placeholder="Confirm Password"
              id="demo-helper-text-misaligned-no-helper"
              {...register('confirm_password', {
                required: 'You must specify a password',
                validate: value => value === watch('password') || "passwords don't match",
              })}
            />
            {errors.confirm_password && <span style={{ color: 'red' }}>{errors.confirm_password.message}</span>}
          </div>
        </>
        <div className="div-span">{spanError && <span className="span-error">{spanError}</span>}</div>
      </div>
      <button className="login-button" type="submit">
        Sign up
      </button>
      <div className="registration-text">
        <span>Already have an account? </span>
        <a className="registration-link" href={routes.login}>
          Login
        </a>
      </div>
    </form>
  );
};

export default Registration;
